import React from "react";
import * as styles from  './Events.module.scss';
import PremierRightArrow from "../../svg/PremierRightArrow";
import EventTextEditor from "../EventTextEditor/EventTextEditor";

const Events = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <section className={styles.eventsContainer} id={'upcoming'}>
      <h2 className={styles.headerTitle}>{data?.title}</h2>
      <div className={styles.eventListContainer}>
        {data?.eventList?.map((event, index) => (
          <div key={index}>
            {event.showElement &&
              <div className={styles.event} key={index}>
                {event?.image?.asset?.url &&
                  <img src={event?.image.asset.url} alt={'event'}/>
                }
                <div className={styles.eventTextContainer}>
                  <p className={styles.title}>{event?.title}</p>
                  <p className={styles.time}>{event?.date}</p>
                  <p className={styles.place}>{event?.placeTime?.placeDesc}</p>
                  <p className={styles.key}>Key Speakers:</p>
                  {event?.speakers?.map((speaker, index) => (
                    <div key={index}>
                      {speaker.showElement &&
                        <p className={styles.speaker}>{speaker?.name}, {speaker?.position}</p>
                      }
                    </div>
                  ))}
                  <p className={styles.eventDesc}>Event Description:</p>
                  {event?.description?.showElement && 
                    <div className={styles.description}>
                      <EventTextEditor data={event?.description?.textEditor}/>
                    </div>
                  }
                  <div className={styles.buttonContainer}>
                    {event?.buttons?.map((button, index) => (
                      <div className={styles.button} key={index}>
                        {button.type !== 'info' && button.showElement ?
                          <a href={button?.link} className={(button?.type === 'webLink' ? styles.webLink : styles.register)}>{button?.text}{button?.type === 'register' && <PremierRightArrow/>}</a>
                          : button.showElement ?
                          <p className={styles.info}>{button?.text}</p>
                          :
                          <></>
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          </div>
        ))}
      </div>
    </section>
  )
}

export default Events;
