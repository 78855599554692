import React from "react";
import * as styles from './Contact.module.scss';
import EventTextEditor from "../EventTextEditor/EventTextEditor";

const Contact = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <section className={styles.contactContainer} id={'contact'}>
      <h2 className={styles.title}>{data?.title}</h2>
      {data?.description?.showElement &&
        <EventTextEditor data={data?.description?.textEditor}/>
      }
    </section>
  )
}

export default Contact;
