import React from "react";
import * as styles from  './Highlights.module.scss';
import PremierRightArrow from "../../svg/PremierRightArrow";
import Location from "../../svg/Location";
import Clock from "../../svg/Clock";
import useMediaQuery from "../../../utils/useMediaQuery";
import DownloadArrow from "../../svg/DownloadArrow";
import EventTextEditor from "../EventTextEditor/EventTextEditor";
import { PortableText } from "@portabletext/react";

const Highlights = (props) => {
  const { data } = props;
  const isMobile = useMediaQuery('(max-width: 700px)');

  // check if any of the notables have showElement set to true
  // const hasShowElement = data?.event?.notables?.some(notable => notable?.showElement);

  return (
    <section className={styles.highlightsContainer} id={'highlight'}>
      <h2 className={styles.headerTitle}>{data?.title}</h2>
      <div className={styles.eventContainer}>
      {(data?.event?.image.asset.url || data?.event?.mobileImage.asset.url ) &&
        <div className={styles.imageContainer}>
          {data?.event?.mobileImage.asset.url && isMobile ?
            <img src={data?.event?.mobileImage.asset.url} alt={'highlight'}/>
          :
            <img src={data?.event?.image.asset.url} alt={'highlight'}/>
          }
        </div>
      }
        <div className={styles.eventTextContainer}>
          <div className={styles.eventHeader}>
            <div className={styles.headerText}>
              <h3 className={styles.eventTitle}>{data?.event?.title}</h3>
              <p className={styles.eventDate}>{data?.event?.date}</p>
            </div>
            <div className={styles.headerButton}>
              <div>
              <a href={data?.event?.button?.link}>{data?.event?.button?.text} <PremierRightArrow/></a>
              </div>
              {data?.event?.buttonFootnote?.showElement && data?.event?.buttonFootnote?.textEditor &&
                <PortableText value={data?.event?.buttonFootnote?.textEditor}/>
              }
            </div>
          </div>
          <div className={styles.placeTime}>
            <div className={styles.place}>
              <Location/>
              <div className={styles.textContainer}>
                <div>
                  <p>{data?.event?.placeTime?.placeTitle}</p>
                  <p>{data?.event?.placeTime?.placeDesc}</p>
                </div>
                <a href={data?.event?.placeTime?.placeLink?.link}>{data?.event?.placeTime?.placeLink?.text} <PremierRightArrow width="7"/></a>
              </div>
            </div>
            <div className={styles.time}>
              <Clock/>
              <div className={styles.textContainer}>
                <div>
                  <p>Event Beginning:</p>
                  <p>{data?.event?.placeTime?.timeStart}</p>
                </div>
                <div>
                  <p>Event Ending:</p>
                  <p>{data?.event?.placeTime?.timeEnd}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.spacing}>
            <div className={styles.themeAgenda}>
              <div className={styles.themeContainer}>
                <div className={styles.desc}>
                  <p>Description:</p>
                  {data?.event?.theme?.descTitle &&
                    <p>{data?.event?.theme?.descTitle}</p>
                  }
                  {data?.event?.theme?.description?.showElement &&
                    <div className={styles.themeDesc}>
                      <EventTextEditor data={data?.event?.theme?.description?.textEditor}/>
                    </div>
                  }
                </div>
                <div>
                  <p>Logistics:</p>
                  {data?.event?.theme?.themeTitle &&
                    <p>{data?.event?.theme?.themeTitle}</p>
                  }
                  {data?.event?.theme?.meetingTheme?.showElement &&
                    <div className={styles.themeDesc}>
                      <EventTextEditor data={data?.event?.theme?.meetingTheme?.textEditor}/>
                    </div>
                  }
                </div>
              </div>
              <div className={styles.agendaContainer}>
                <p className={styles.agendaTitle}>Agenda:</p>
                <div className={styles.agenda}>
                  {data?.event?.agenda?.itinerary?.map((date, index) => (
                    <div className={styles.date} key={index}>
                      <p className={styles.dateTitle}>{date?.date}</p>
                      {date.times.map((section, index) => (
                        <div className={styles.section} key={index}>
                        {section._type === 'timeSlot' &&
                          <div className={styles.timeSlot}>
                            <p className={styles.time}>{section?.time}</p>
                            <div className={styles.event}>
                              {section?.event?.textEditor && section?.event?.showElement &&
                                <EventTextEditor data={section?.event?.textEditor}/>
                              }
                            </div>
                          </div>
                        }
                        {section._type === 'sectionTitle' && section?.showElement && <div className={styles.sectionTitle}><EventTextEditor data={section?.textEditor}/></div>}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                {data?.event?.agenda?.footnote?.showElement &&
                <div className={styles.footnote}>
                  <EventTextEditor data={data?.event?.agenda?.footnote?.textEditor}/>
                </div>
                }
                {data?.event?.agenda?.buttonText && data?.event?.agenda?.downloadLink &&
                <div className={styles.downloadEventButton}>
                  <a href={data?.event?.agenda?.downloadLink} target='_blank' rel='noopener noreferrer'>{data?.event?.agenda?.buttonText}<DownloadArrow/></a>
                </div>
                }
              </div>
            </div>
            {/* ToDo: use hasShowElement above to show/hide section */}
            {/* <div>
              <div className={styles.notables}>
                {data?.event?.notables.map((group, index) => (
                  <div className={styles.group} key={index}>
                    <p className={styles.groupTitle}>{group?.title}</p>
                    {group?.list?.map((listItem, index) => (
                      <div className={styles.itemContainer} key={index}>
                        <ListArrow />
                        <div>
                          <p className={styles.listTitle}>{listItem?.title}</p>
                          {listItem?.description?.showElement &&
                          <div className={styles.listDesc}>
                            <PortableText value={listItem?.description?.textEditor}/>
                          </div>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Highlights;
