import React from "react";

const DownloadArrow = (props) => {
    return (
        <svg
        width="12"
        height="15" 
        viewBox="0 0 12 15"
        fill="#DA437E"
        xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8373 5.19266C11.0542 5.41674 11.0542 5.78003 10.8373 6.0041L6 11L1.16272 6.0041C0.945761 5.78003 0.945761 5.41674 1.16272 5.19266C1.37968 4.96859 1.73143 4.96859 1.94839 5.19266L5.44444 8.80335L5.44444 1.57377C5.44444 1.25689 5.69317 1 6 1C6.30682 1 6.55556 1.25689 6.55556 1.57377L6.55556 8.80335L10.0516 5.19266C10.2686 4.96859 10.6203 4.96859 10.8373 5.19266Z" fill="#DA437E" stroke="#DA437E" strokeWidth="0.5" strokeLinecap="round"/>
            <line x1="1" y1="14" x2="11" y2="14" stroke="#DA437E" strokeWidth="2" strokeLinecap="round"/>
        </svg>

    )
}

export default DownloadArrow;