import React from "react";
import { PortableText } from "@portabletext/react";
// import * as styles from './EventTextEditor.module.scss';

const EventTextEditor = (props) => {
  const { data } = props;
  // console.log(data);
  const scrollToId = (id) => {
    // console.log('John O’Leary' === id);
    // console.log(id);
    // console.log('John O’Leary');
    document.getElementById(id)?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
  const customComponents = {
    marks: {
      speakerInfo: ({children, value}) => (
        <button onClick={() => scrollToId(value.speakerName)}>{children}</button>
      ),
      link: ({children, value}) => (
        <a href={value.url} target="_blank" rel="noopener noreferrer">{children}</a>
      )
    }
  }
  return (
        <PortableText value={data} components={customComponents}/>
  )
}

export default EventTextEditor;
