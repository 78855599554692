// extracted by mini-css-extract-plugin
export var button = "Events-module--button--562de";
export var buttonContainer = "Events-module--buttonContainer--1f5f2";
export var description = "Events-module--description--a4eab";
export var event = "Events-module--event--b55d9";
export var eventDesc = "Events-module--eventDesc--0b4db";
export var eventListContainer = "Events-module--eventListContainer--9076f";
export var eventTextContainer = "Events-module--eventTextContainer--b49dd";
export var eventsContainer = "Events-module--eventsContainer--71a3e";
export var headerTitle = "Events-module--headerTitle--1869f";
export var info = "Events-module--info--22c1c";
export var key = "Events-module--key--40780";
export var place = "Events-module--place--dd5d2";
export var register = "Events-module--register--86dab";
export var speaker = "Events-module--speaker--3994e";
export var time = "Events-module--time--33571";
export var title = "Events-module--title--4d8a5";
export var webLink = "Events-module--webLink--24b47";