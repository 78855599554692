import * as React from "react"
const PremierRightArrow = ({width = '15'}: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m6.655 0 7.53 7.53-7.53 7.53L5.595 14l5.72-5.72H0v-1.5h11.314l-5.72-5.72L6.656 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default PremierRightArrow