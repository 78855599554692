// import Head from 'next/head'
import React from 'react';
import {graphql} from 'gatsby'
import * as styles from '../styles/premierEvents.module.scss'
// import { client } from "../utils/sanityClient";
import Hero from '../components/PremierEvents/Hero'
import Highlights from '../components/PremierEvents/Highlights'
import Events from '../components/PremierEvents/Events'
import Speakers from '../components/PremierEvents/Speakers'
import Contact from '../components/PremierEvents/Contact'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

// const inter = Inter({ subsets: ['latin'] })

// export const getStaticProps = async () => {
//   const sanityData = await client.fetch(`
//   *[_type == 'eventPage' && pageName == 'Main Page'] {
//     ...,
//     hero {
//       ...,
//       'imgWidth': image.asset->metadata.dimensions.width,
//       'imgHeight': image.asset->metadata.dimensions.height,
//       'imgUrl': image.asset->url
//     },
//     highlight {
//       ...,
//       event-> {
//         ...,
//         'imgWidth': image.asset->metadata.dimensions.width,
//         'imgHeight': image.asset->metadata.dimensions.height,
//         'imgUrl': image.asset->url,
//         'imgWidthMobile': mobileImage.asset->metadata.dimensions.width,
//         'imgHeightMobile': mobileImage.asset->metadata.dimensions.height,
//         'imgUrlMobile': mobileImage.asset->url,
//         agenda {
//           ...,
//           'downloadLink': buttonFile.asset->url
//         }
//       }
//     },
//     events {
//       ...,
//       eventList[]-> {
//         ...,
//         'imgWidth': image.asset->metadata.dimensions.width,
//         'imgHeight': image.asset->metadata.dimensions.height,
//         'imgUrl': image.asset->url,
//         speakers[]->
//       }
//     },
//     speakers {
//       ...,
//       speakerList[]-> {
//         ...,
//         'imgWidth': image.asset->metadata.dimensions.width,
//         'imgHeight': image.asset->metadata.dimensions.height,
//         'imgUrl': image.asset->url
//       }
//     }
//   }
//   `);
//   const data = sanityData[0];
//   return {
//     props: {
//       data,
//     },
//   }
// }

export const query = graphql`
    query HundredTopQuery {
        allSanityEventPage {
            nodes {
                _rawSpeakers(resolveReferences: {maxDepth: 10})
                _rawHighlight(resolveReferences: {maxDepth: 10})
                _rawHero(resolveReferences: {maxDepth: 10})
                _rawHeader(resolveReferences: {maxDepth: 10})
                _rawEvents(resolveReferences: {maxDepth: 10})
                _rawContact(resolveReferences: {maxDepth: 10})
                slug {
                    current
                }
            }
        }
    }
  `

const PremierEvents = (props) => {
  const { data, pageContext } = props;
  const {eventSlug} = pageContext;
    const pageData = data.allSanityEventPage.nodes.find(event => 
        event.slug.current === eventSlug.current);
    let premierEventData = pageData;
    // console.log(premierEventData);
  return (
    <>
      {/* <Head>
        <title>Premier Executive Events</title>
        <meta name="description" content="Our exclusive, invite-only, top-to-top forum will bring together forward-thinking leaders in healthcare to discuss the key trends and use cases surrounding artificial intelligence (AI) in healthcare. Experts from the tech sector, health systems, health plans, patient advocacy groups and academia will engage in thought leadership discussions regarding the use of AI, as well as frank debate on the barriers to adoption and ethical issues that remain unanswered." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon-100x100.svg" />
        <link rel="icon" href="/favicon-16x16.svg" sizes="16x16" />
        <link rel="icon" href="/favicon-100x100.svg" sizes="100x100" />
        <link rel="apple-touch-icon" href="/favicon-16x16" sizes="16x16" />
        <link rel="apple-touch-icon" href="/favicon-32x32" sizes="32x32" />
        <link rel="apple-touch-icon" href="/favicon-64x64" sizes="64x64" />
        <link rel="apple-touch-icon" href="/favicon-100x100" sizes="100x100" />
        <meta property="og:title" content="Premier Executive Events" />
        <meta
          property="og:description"
          content="Our exclusive, invite-only, top-to-top forum will bring together forward-thinking leaders in healthcare to discuss the key trends and use cases surrounding artificial intelligence (AI) in healthcare. Experts from the tech sector, health systems, health plans, patient advocacy groups and academia will engage in thought leadership discussions regarding the use of AI, as well as frank debate on the barriers to adoption and ethical issues that remain unanswered."
        />
        <meta property="og:site_name" content="Premier Executive Events" />
        <meta
          property="og:image"
          content="/sharing-image.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@PremierHA" />
        <meta name="twitter:title" content="Premier Executive Events" />
        <meta
          name="twitter:description"
          content="Our exclusive, invite-only, top-to-top forum will bring together forward-thinking leaders in healthcare to discuss the key trends and use cases surrounding artificial intelligence (AI) in healthcare. Experts from the tech sector, health systems, health plans, patient advocacy groups and academia will engage in thought leadership discussions regarding the use of AI, as well as frank debate on the barriers to adoption and ethical issues that remain unanswered."
        />
        <meta
          name="twitter:image"
          content="/sharing-image.png"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
      </Head> */}
      {/* <head>
        <title>100 Top Hospitals® Awards Gala</title>
      </head> */}
      <Layout>
        <main className={styles.main}>
          {/* {premierEventData._rawHeader && premierEventData._rawHeader.showElement &&
              <Header data={premierEventData._rawHeader}/>
          } */}
          {premierEventData._rawHero && premierEventData._rawHero.showElement &&
            <Hero data={premierEventData._rawHero}/>
          }
          {premierEventData._rawHighlight && premierEventData._rawHighlight.showElement &&
            <Highlights data={premierEventData._rawHighlight}/>
          }
          {premierEventData._rawEvents && premierEventData._rawEvents.showElement &&
            <Events data={premierEventData._rawEvents}/>
          }
          {premierEventData._rawSpeakers && premierEventData._rawSpeakers.showElement &&
            <Speakers data={premierEventData._rawSpeakers}/>
          }
          {premierEventData._rawContact && premierEventData._rawContact.showElement &&
            <Contact data={premierEventData._rawContact}/>
          }
        </main>
      </Layout>
    </>
  )
}

export default PremierEvents

export const Head = (props) => {
  const data = {
    title: '100 Top Hospitals® Awards Gala',
    seo: {
      title: '100 Top Hospitals® Awards Gala',
      description: 'Join us for the 100 Top Hospitals® Awards Gala celebrating the top of healthcare. CEOs, quality leaders and industry experts will come together to network and celebrate over the course of the event - including a welcome cocktail reception, formal awards dinner, and unique morning breakfast.',
      image: {
        asset: {
          url: 'https://firebasestorage.googleapis.com/v0/b/pinc-ai-phase2.appspot.com/o/premier-events%2Fsharing%20image.jpg?alt=media&token=c18ec595-b159-4a13-8969-a9d6ea9792e9'
        }
      }
    }
  }
  return (
    <Seo title={data.title} seo={data.seo}>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@PremierHA" />
        <meta name="twitter:title" content={data.seo.title} />
        <meta name="twitter:description" content={data.seo.description} />
        <meta name="twitter:image" content={data.seo.image.asset.url} />
    </Seo>
  )
}