import React, { useEffect, useRef } from "react";
import * as styles from  './Hero.module.scss';
import RightArrow from "../../svg/RightArrow";
import EventTextEditor from "../EventTextEditor/EventTextEditor";

const Hero = (props) => {
  const { data } = props;
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  // registered trademark symbol fix - wrap in span with class
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerHTML = titleRef.current.innerHTML.replace(/®/g, `<sup class="${styles.rball}">®</sup>`);
    }
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = descriptionRef.current.innerHTML.replace(/™!/g, `™<span class="${styles.tmSpacing}">!</span>`);
    }
  }, []);


  return (
    <section className={styles.heroContainer} >
      {data?.image?.asset?.url &&
      <div className={styles.imageContainer}>
        <img src={data.image.asset.url} alt={'hero'}/>
      </div>
      }
      <div className={styles.textContainer}>
        {data.title?.showElement &&
          <div ref={titleRef}>
            <EventTextEditor data={data.title.textEditor}/>
          </div>          
        }
        {data.description?.showElement &&
          <div className={styles.description} ref={descriptionRef}>
            <EventTextEditor data={data.description.textEditor}/>
          </div>
        }
        {data.link?.showElement &&
          <a href={data.link.link} className={styles.link}>{data.link.text} <RightArrow/></a>
        }
      </div>
    </section>
  )
}

export default Hero;
