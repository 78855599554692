// extracted by mini-css-extract-plugin
export var description = "Speakers-module--description--69292";
export var events = "Speakers-module--events--a3d1d";
export var headerTitle = "Speakers-module--headerTitle--b24ec";
export var imageContainer = "Speakers-module--imageContainer--5319e";
export var linked = "Speakers-module--linked--95d55";
export var name = "Speakers-module--name--e6eec";
export var position = "Speakers-module--position--c49fd";
export var speakerContainer = "Speakers-module--speakerContainer--37df4";
export var speakersContainer = "Speakers-module--speakersContainer--2d6d9";
export var tag = "Speakers-module--tag--f8c6f";
export var tagContainer = "Speakers-module--tagContainer--3fe37";
export var textContainer = "Speakers-module--textContainer--86dae";