import React from "react";
import * as styles from  './Speakers.module.scss';
import EventTextEditor from "../EventTextEditor/EventTextEditor";

const Speakers = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <section className={styles.speakersContainer} id={'speakers'}>
      <p className={styles.headerTitle}>{data?.title}</p>
      {data?.speakerList?.map((speaker, index) => (
        <div className={styles.speakerContainer} key={index} id={speaker?.name}>
          {speaker?.image?.asset?.url &&
          <div className={styles.imageContainer}>
            <img src={speaker?.image?.asset?.url} alt={'speakers'}/>
          </div>
          }
          <div className={styles.textContainer}>
            <p className={styles.name}>{speaker?.name} </p>
            <p className={styles.position}>{speaker?.position}</p>
            {speaker?.description?.showElement &&
              <div className={styles.description}>
                <EventTextEditor data={speaker?.description?.textEditor}/>
              </div>
            }
            <div className={styles.events}>
              {speaker?.eventTags && speaker?.eventTags.length !== 0 &&
                <p>Event(s) Speaking:</p>
              }
              <div className={styles.tagContainer}>
                {speaker?.eventTags?.map((tag, index) => (
                  <div className={styles.tag} key={index}>
                    {index !== 0 && <span>|</span>}
                    {tag.showElement && 
                    <a href={tag?.link} className={styles.tag + " " + (tag?.link ? " " + styles.linked : '')}>{tag?.text}</a>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Speakers;
